import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const CustomizedDialogs = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" size="small" style = {{ background: 'none!important', border: 'none' ,padding: '0!important', color: '#008eff',textDecoration: 'underline',
  cursor: 'pointer', textTransform : 'none'}} onClick={handleClickOpen}>
        Learn More
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Work Flow
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
         <b> The app workflow follows three simple steps:</b>
                        <br />
                        <ul>
                        <li>
                        Step 1: Provide a list of known contacts. Users have the option to import from an existing cloud address book (e.g., Outlook contacts or Google contacts), upload from a file (e.g., CSV or VCF), or manually enter a list of email addresses.
                        </li>
                        <br />
                        <li>
                        Step 2: The App will scan the list of contacts to identify: (a) counterparties you are already connected to on Symphony; (b) counterparties who are on Symphony but you are not connected to yet; (c) counterparties not yet on Symphony.
                        </li>
                        <br />
                        <li>
                        Step 3: Users can then choose to send a connection request or invite people who are not on Symphony to join the community. All actions can be done either person by person or in bulk (e.g., send a connection request to many people with one click).
                        </li>
                        </ul>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
