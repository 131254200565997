import { useState } from 'react';
import { Box, Text, InputField , NavTabs, Separator, MessageBox} from 'symphony-bdk-ui-toolkit';
//import styled from 'styled-components';
import Stack from '@mui/material/Stack';
import Button from './Button';
import { GoogleContactImport } from './GoogleContactImport';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { OutlookImport } from './OutlookImport';
import '@symphony-ui/uitoolkit-styles/dist/css/uitoolkit.css';
//import '@symphony-ui/uitoolkit-components/styles';
import { Nav, NavItem, NavProps, style} from '@symphony-ui/uitoolkit-components';
import { fontSize } from '@mui/system';
var vCard = require('vcard-parser');

const podId = localStorage.getItem('POD_ID');
const googleButtonNotVisible = ['1024', '5006'];
const UploadCSV = ({ onCSV, children }) => {
    const onChange = async ({ target }) => {
        const files = target.files;
        const text = await files.item(0).text();
        onCSV(text);
        target.value = null;
    }
    return (
        <div>
            <input type="file" id="fileElem" accept=".csv" style={{ display: 'none' }} onChange={onChange} />
            <Button onClick={() => document.getElementById("fileElem").click()}>{children}</Button>
        </div>
    )
}

const UploadVCF = ({ onCSV, children }) => {
    const onChange = async ({ target }) => {
        const files = target.files;
        const emailsVcf = [];
        //need to push all the emails one by one from multiple vcfs into an array to be split by comma
        if(files.length < 2){
            const te = await files.item(0).text();
            var varder = vCard.parse(te);
            console.log(varder);
        }
        for(let i = 0; i < files.length; i++){
            const text = await files.item(i).text();
            var card = vCard.parse(text);
            if(card.hasOwnProperty('email')){
                emailsVcf.push(card.email[0].value);
            }
        }
        const finalStringForEmails = emailsVcf.toString();
        onCSV(finalStringForEmails);
        target.value = null;
    }
    return (
        <div>
            <input type="file" id="fileElemVCF" multiple accept=".vcf" style={{ display: 'none' }} onChange={onChange} />
            <Button onClick={() => document.getElementById("fileElemVCF").click()}>{children}</Button>
        </div>
    )
}

const sortByEmail = (a, b) => {
    if (!a.emailAddress || !b.emailAddress) return 0;
    if (a.emailAddress.toUpperCase() < b.emailAddress.toUpperCase()) return -1;
    return 1;
};


export const Import = (props) => {

    const { onImportComplete , activeTab} = props;
    const [currStep, setCurrStep] = useState(1);
    const [alert, setAlert] = useState('');
    let alertStyle = {
        display: 'none'
    };
    alert && (alertStyle = {
        ...alertStyle,
        display: 'block'
    });
    const [copyPasteValue, setCopyPasteValue] = useState('');
    function handleChange(event){
        setCurrStep(event.target.value);
        //useState(event.target.value);
    }
    const [activeItemId, setActiveItemId] = useState(0);

    const onActiveTabChange = (navItem: NavItem) => {
      setActiveItemId(navItem.id);
    };

    const settingTheAlert = () => {
        console.log("Wrong Format or Empty");
        setAlert(`File format is invalid or file is empty. Please try again.`);
        setTimeout(() => setAlert(''), 6000);
    }
    const isValidEmail = (email) => {
        const EMAIL_RE = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isValid = EMAIL_RE.test(String(email).toLowerCase());

        return isValid;
    }

    const onGoogleSuccess = (resp) => {
        // map google respose to contacts
        const contacts = resp
            .map(x => ({ id: x.email, emailAddress: x.email, displayName: x.title, isChecked: true }))
            .filter(x => isValidEmail(x.emailAddress))
            .sort(sortByEmail);

        onImportComplete(contacts);
    }

    const getContactsFromText = (text) => {
        if(text == "" || text.trim() == '' || text === undefined){
            settingTheAlert();
            return;
        }
        // process text to list of email addresses
        // parser typical Email Address formats e.g. Bob Example <bob@example.com> - @"""?((?<name>.*?)""?\s*<)?(?<email>[^>]*)"
        // trim spoaces and remove blank emails
        const contacts = text.split(/[,;\n]/)
            .filter(x => x.length > 0)
            .map(x => x.trim())
            .map(x => {
                const [, , displayName = '', emailAddress = ''] = x.match(/"?((.*?)"?\s*<)?([^">]*)/);
                if (!emailAddress && !displayName) return null;
                return { id: emailAddress, emailAddress, displayName, isChecked: true };
            })
            .filter(x => x && isValidEmail(x.emailAddress))
            .sort(sortByEmail);
        if(contacts.length < 1){
            settingTheAlert();
            return;
        }

        onImportComplete(contacts);
    }

    return (
        <Box>
        <div>
                <Nav
                    items={[
                        {
                          id: 0,
                          label: <Text size="large" style={{
                            textTransform: 'none'}}>Cloud Address Book</Text>
                        },
                        {
                          id: 1,
                          label: <Text size="large" style={{
                            textTransform: 'none'}}>Upload File</Text>
                        },
                        {
                          id: 2,
                          label: <Text size="large" style={{
                            textTransform: 'none'}}>Enter Email Address</Text>
                        }
                      ]}
                    onActiveTabChange={onActiveTabChange}
                    activeItemId={activeItemId}
                />
                <div className="tk-ml-2">
                    {activeItemId === 0 ? (
                    <div label={`CLOUD ADDRESS BOOK...`}>
                    <Box>
                        <div></div>
                        <Text>
                           <b>You can import contacts from Outlook or Google Contacts.</b>
                        </Text>
                        <Text>Choose the account type and sign in to authorize the app to access your contact data.</Text>
                        <Stack direction="row" spacing={4}>
                        <OutlookImport 
                            clientId="dbdb4986-c52f-4b8a-9525-68b4727d71c4"
                            maxResults={10000}
                            onSuccess={onGoogleSuccess} />
                        
                        {!googleButtonNotVisible.includes(podId) && <GoogleContactImport 
                            clientId="821358702441-bbooa703mo7d2o2oehpotqgjnuu6s8ld.apps.googleusercontent.com"
                            maxResults={5000}
                            onSuccess={onGoogleSuccess} />}
                        </Stack>
                    </Box>
                </div>
                    ) : (
                    <div>{activeItemId === 1 ? <div label={`UPLOAD FILE`}>
                    <Box>
                    <div></div>
                        <Text>
                           <b>You can import contacts from a file on your computer.</b>
                        </Text>
                        <Text>Select a CSV or vCard file with a list of email addresses in a single column.
                        <br />This method is commonly used when pasting an existing list from another tool (such as Outlook contacts export).
                        </Text>
                        <MessageBox style={alertStyle}>{alert}</MessageBox>
                        <Box>
                        <Stack direction="row" spacing={5}>
                        <UploadCSV onCSV={getContactsFromText}>IMPORT CSV FILE...</UploadCSV>
                        <UploadVCF onCSV={getContactsFromText}>IMPORT VCF FILE(s)...</UploadVCF>
                        </Stack>
                        </Box>
                    </Box>
                </div> : 
                <div label={`ENTER EMAIL ADDRESSES`}>
                <Box>
                    {/* <Text type="primary" size="large">Copy and Paste</Text> */}
                    <div></div>
                    <Text>
                        <b>You can provide a list of email addresses to import in the space below</b>
                    </Text>
                    <Text>Email addresses should be separated by a comma (,) or semicolon (;) or listed on separate lines. 
                        <br  />This method is commonly used when pasting an existing list from another source.</Text>
                    <MessageBox style={alertStyle}>{alert}</MessageBox>
                    <InputField type="textarea" value={copyPasteValue} onChange={e => setCopyPasteValue(e.target.value)} />
                    <Box horizontal>
                        <Button disabled={copyPasteValue.length < 2 || copyPasteValue.trim() == ''} onClick={e => getContactsFromText(copyPasteValue)}>IMPORT FROM EMAIL</Button>
                    </Box>
                </Box>
                </div>}</div>
                    )}
                </div>
    </div>
         {/* to be deleted  */}
         {/*
        <Box>
            
            <CustomFormControl size = 'small' fullWidth = {false} focused = {true} variant = 'standard' color = 'primary'>
                <InputLabel id="demo-simple-select-label"><Text>Source</Text></InputLabel>
                <Select
                    value={currStep}
                    label="Source"
                    onChange={handleChange}
                >
                    <MenuItem value={1}><Text>Cloud Address Book</Text></MenuItem>
                    <MenuItem value={2}><Text>Upload a File</Text></MenuItem>
                    <MenuItem value={3}><Text>Type Email</Text></MenuItem>
                </Select>
            </CustomFormControl>
            {currStep == 1 && (
                <Box>
                    <div label={`Import from Address Book`}>
                    <Box>
                       
                        <Text>
                            Select this to source contacts from your Outlook or Google address book.
                            A pop-up window will show asking you to choose the Outlook or Google account (some users may have multiple accounts), 
                            and authorize access for the App to the account contact list.
                        </Text>
                        <Stack direction="row" spacing={5}>
                        <OutlookImport 
                            clientId="18de4c02-579f-4f68-9640-47045d72372e"
                            maxResults={10000}
                            onSuccess={onGoogleSuccess} />
                        
                        <GoogleContactImport 
                            clientId="821358702441-bbooa703mo7d2o2oehpotqgjnuu6s8ld.apps.googleusercontent.com"
                            maxResults={5000}
                            onSuccess={onGoogleSuccess} />
                        </Stack>
                    </Box>
                    </div>
                </Box>
            )

            }
            {currStep == 2 && (
                <Box>
                    <div label={`Upload a File`}>
                    <Box>

                        <Text>
                            Select this to source contacts from a file on your computer. 
                            The file has to be a CSV file with a simple list of email addresses as the only column. 
                            This method is commonly used when importing an existing list from another tool (e.g. Outlook contacts export).
                        </Text>
                        <Box>
                        <Stack direction="row" spacing={5}>
                        <UploadCSV onCSV={getContactsFromText}>Import CSV File...</UploadCSV>
                        <UploadVCF onCSV={getContactsFromText}>Import VCF File...</UploadVCF>
                        </Stack>
                        </Box>
                    </Box>
                    </div>
                </Box>
            )
            }
            
             {currStep == 3 && (
                <Box> 
                    <div label={`Copy Paste Text`}>
                    <Box>
                       
                        <Text>
                            Select this to provide a list of email addresses directly into the App. 
                            Email addresses should be separated by a comma (,) or semicolon (;) or listed on separate lines. 
                            This method is commonly used when pasting an existing list from another source. 
                        </Text>
                        <InputField type="textarea" value={copyPasteValue} onChange={e => setCopyPasteValue(e.target.value)} />
                        <Box horizontal>
                            <Button disabled={copyPasteValue.length < 3} onClick={e => getContactsFromText(copyPasteValue)}>Import Text...</Button>
                        </Box>
                    </Box>
                    </div>
                </Box>
            )
            }
        
            </Box>
        
        */}
        </Box>
    );
}
